import { useState } from 'react'
import FileUpload from '../Chat/ChatMessageUpload/FileUpload'
import { Button, Typography, Box } from '@mui/material'
import { uploadPracticeSuiteInvoice } from '~/api/PracticeSuiteService'
import Loader from '../Loader'
import { makeStyles } from 'tss-react/mui'
import { useDispatch } from 'react-redux'
import { queueNotification } from '~/redux/actions/notifications'

const useStyles = makeStyles()(theme => {
  return {
    container: {
      padding: theme.spacing(2),
    },
  }
})

const PracticeSuiteInvoiceSettings = () => {
  const { classes } = useStyles()
  const [file, setFile] = useState<any>(null)
  const [uploadKey, setUploadKey] = useState<number>(0)
  const [sending, setSending] = useState<boolean>(false)
  const dispatch = useDispatch()

  const uploadFile = async () => {
    if (file) {
      try {
        const data = new FormData()
        data.append('file', (file as any)!.data)
        data.append('title', (file as any)!.data.name)
        setSending(true)
        await uploadPracticeSuiteInvoice(data)
        dispatch(
          queueNotification({
            message: `Successfully uploaded file.`,
            variant: 'success',
          })
        )
      } catch (e) {
        if (e instanceof Error) {
          console.warn('Error uploading file', e)
          dispatch(
            queueNotification({
              message: `Unable upload file.`,
              variant: 'error',
            })
          )
          handleRemoveAttachment()
          setSending(false)
          throw e
        }
      }
      handleRemoveAttachment()
      setSending(false)
    }
  }

  const handleRemoveAttachment = () => {
    setFile(null)
    setUploadKey(uploadKey + 1)
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6">Upload Practice Suite Invoice</Typography>
      <Typography>Select file</Typography>
      <Box>
        <FileUpload
          maxFiles={1}
          files={null}
          key={uploadKey}
          handleFile={fileWithType => setFile(fileWithType)}
          handleClear={() => setUploadKey(uploadKey + 1)}
          supportedImageTypes={[]}
          supportedDocTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
          supportMessage="Supports Excel Documents Only"
        />
      </Box>
      {file && <Typography>{(file as any)!.data.name}</Typography>}
      <Button color="primary" onClick={uploadFile} disabled={!file || sending}>
        {sending ? <Loader inline /> : 'Upload'}
      </Button>
    </div>
  )
}

export default PracticeSuiteInvoiceSettings
