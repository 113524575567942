import { useQuery } from '~/components/Providers/ApiProvider'
import { apiClient, apiClientWithoutRequestDecamelization } from './rest'

export interface CanUserBookAppointmentsV2 {
  videoNew: boolean
  video: boolean
  urgentVisit: boolean
  behavioralHealth?: boolean
  healthGuideConsult?: boolean
  annualWellness?: boolean
  focused?: boolean
  annualWellnessVisitEstablished?: boolean
  annualWellnessVisitNew?: boolean
}

export type VisitReason = keyof CanUserBookAppointmentsV2

export const getCanUserBookAppointmentsV2 = async (id: number) => {
  return apiClient.rest.get<CanUserBookAppointmentsV2>(`/user/${id}/can_book_appointments_v2`)
}

// TODO: Clean this type
export interface AvailablePhysicianSlot {
  id?: number
  elationId?: number
  physicianId: number
  inCareTeam: boolean
  scheduledDate?: string
  reason: VisitReason
  physicianFirstName?: string
  physicianLastName?: string
  physicianTitle?: string
  physicianName?: string
  slots?: string[]
}

export const AppointmentTypeMapping = {
  video: 'Video',
  videoNew: 'Video-New',
  annualWellness: 'Annual Wellness',
  healthGuideConsult: 'Health Guide Consult',
  behavioralHealth: 'Behavioral Health',
  focused: 'Focused',
  annualWellnessVisitEstablished: 'Annual Wellness Visit Established',
  annualWellnessVisitNew: 'Annual Wellness Visit New',
}

const stripMilliseconds = (date: Date) => date.toISOString().replace(/\.\d+/, '')

export const availablePhysiciansWithSlots = (
  patientId: number,
  reason: VisitReason,
  from: Date,
  to: Date,
  isDynamicSchedulingV2Enabled: boolean
) => {
  if (isDynamicSchedulingV2Enabled) {
    return apiClientWithoutRequestDecamelization.rest.get<any>(
      '/bff/crm/available-appointment-slots',
      {
        patientId,
        reason: AppointmentTypeMapping[reason],
        start_time: stripMilliseconds(from),
        end_time: stripMilliseconds(to),
      }
    )
  }
  return apiClient.rest.get<AvailablePhysicianSlot[]>(
    '/appointment/slot/provider/available-physicians-with-slots',
    {
      patientId,
      reason: AppointmentTypeMapping[reason],
      start_time: stripMilliseconds(from),
      end_time: stripMilliseconds(to),
    }
  )
}

export const bookAppointment = async payload => {
  const data = await apiClient.rest.patch(
    `/appointment/slot/provider/book-appointment/v2/${payload.appointmentId}`,
    {
      ...payload,
    }
  )
  return data
}

export const bookAppointmentV2 = async payload => {
  return apiClientWithoutRequestDecamelization.rest.post(`/bff/crm/book-appointment/`, {
    description: payload.description,
    otherSymptoms: payload.otherSymptoms,
    patient_id: payload.patientId,
    physician_id: payload.physicianId,
    reason: payload.reason,
    scheduled_date: payload.scheduledDate,
    symptom_ids: payload.symptomIds,
  })
}

export const getCancellationReasons = async () => {
  return apiClient.rest.get(`/appointment/cancellation-reasons/`)
}

export const getSymptoms = async () => {
  return apiClient.rest.get(`/appointment/symptoms/`)
}

export const cancelAppointmentV2 = async payload => {
  const data = await apiClient.rest.post(`/appointment/${payload.id}/cancel/v2/`, {
    ...payload,
  })
  return data
}

export const getVisitDurationRating = async () => {
  return apiClient.rest.get(`/visit-ratings/visit-duration-rating-list/`)
}

export const visitDurationRating = async payload => {
  const data = await apiClient.rest.post(`/visit-ratings/visit-duration/`, {
    ...payload,
  })
  return data
}

export const useVisitDurationRatings = () => {
  return useQuery(
    ['getVisitDurationRating'],
    () => getVisitDurationRating(),
    {},
    {
      error: 'Failed to fetch visit duration rating',
    }
  )
}

export const useGetSymptoms = () => {
  return useQuery(
    ['getSymptoms'],
    () => getSymptoms(),
    {},
    {
      error: 'Failed to fetch visit duration rating',
    }
  )
}
