import React, { useEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import * as ChatService from '~/api/ChatService'
import Loader from '~/components/Loader'

import Mustache from 'mustache'
import Fuse, { FuseOptions } from 'fuse.js'

import '@webscopeio/react-textarea-autocomplete/style.css'
import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete'
import { Box, Divider } from '@mui/material'
import FileUpload, { FileWithType } from './ChatMessageUpload/FileUpload'
import axiosRequest from '~/utils/axios'
import { queueNotification } from '~/redux/actions/notifications'
import { patientName } from '~/utils/users'
import { INPUT_STYLES } from '~/utils/getInputStyles'
import { TextMacro } from '~/redux/slices/macros'
import { logger } from '~/utils/logger'

const MacroItem = ({ entity: { name, template } }) => (
  <div style={{ padding: 6, display: 'flex', maxWidth: 450, fontSize: 12 }}>
    <div
      style={{
        minWidth: 80,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: 500,
      }}
    >
      {name}
    </div>
    <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
      {template}
    </div>
  </div>
)
const MacroLoading = () => <div>Loading</div>

interface IChatInputProps {
  onInput?: (arg0) => void
  sendChat: any
  patient: any
  showIntroductionReminder: boolean
  providerCanProvideClinicalCareToPatient: boolean
  classes?: any
}

export const IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/tiff']
export const DOCUMENT_TYPES = ['application/pdf']

// https://www.fusejs.io/api/options.html
const FUSE_CONFIG: FuseOptions<TextMacro> = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ['name'],
}

export const ChatInput: React.FC<IChatInputProps> = props => {
  const me = useSelector(state => state.me)
  const macros = useSelector(state => state.macros.byId)
  const dispatch = useDispatch()
  const [text, setText] = useState('')
  const [sending, setSending] = useState(false)
  // Determine file type with FileWithType payload shape
  const [files, setFiles] = useState<null | FileWithType[]>(null)
  const [uploadKey, setUploadKey] = useState<number>(0)
  const messagesInputRef = useRef<ReactTextareaAutocomplete>(null)
  // ReactTextareaAutocomplete.current's ref is untyped
  const messagesInputElement = messagesInputRef.current?.textareaRef as null | HTMLTextAreaElement

  const macrosFuseSearch = token => {
    return new Fuse(Object.values(macros), FUSE_CONFIG).search(token)
  }

  const resizeInput = () => {
    setTimeout(() => {
      if (!messagesInputElement) return

      const offset = messagesInputElement.offsetHeight - messagesInputElement.clientHeight
      messagesInputElement.style.height = 'auto'
      messagesInputElement.style.height = `${(messagesInputElement.scrollHeight + offset) / 10}rem`
    }, 0)
  }

  useEffect(() => {
    resizeInput()
  }, [])

  const handleInputEvent = e => {
    if (props.onInput) {
      props.onInput(e)
    }
  }

  const handleSendChat = async () => {
    const { sendChat } = props
    const input = text.trim()
    if (input.length || (files && files?.length > 0)) {
      try {
        logger.info('handleSendChat', { input })
        setSending(true)
        if (files && files?.length > 0) {
          try {
            const attachment = await uploadAttachment()
            const sendChatProps =
              (files[0] as any)!.type === 'image' ? [input, attachment] : [input, null, attachment]
            sendChat(...sendChatProps)
          } catch (e) {
            dispatch(queueNotification({ variant: 'error', message: 'Failed to send message' }))
            logger.error('Failed to send message', e)
            setSending(false)
          }
        } else {
          sendChat(input)
        }
        setSending(false)
        setText('')
        if (messagesInputRef.current) {
          messagesInputRef.current.textareaRef.focus()
        }
        handleRemoveAttachment()
      } catch (e) {
        logger.error('Failed to send message', e)
        setSending(false)
      }
    }
  }

  // Uploads local image to API and returns id of attachment object
  // to attach to a message payload
  const uploadAttachment = async () => {
    try {
      // Declare id, populate with either document or regular image upload
      let id
      const data = new FormData()
      if (files && files.length > 0) {
        data.append('file', (files[0] as any)!.data)
        if ((files[0] as any)!.type === 'document') {
          // @ts-ignore
          data.append('description', null) // TODO: Remove me?
          data.append('title', (files[0] as any)!.data.name)
          const res = await axiosRequest(
            'POST',
            'multipart/formdata',
            true,
            `/documents/providers/me/patients/${patient.id}/files/`,
            data
          )
          id = (res as any).data.id
        } else {
          const res = await ChatService.uploadMessageAttachment(data)
          id = res.id
        }
        return id
      }
    } catch (e) {
      if (e instanceof Error) {
        console.warn('Error uploading image', e)
        throw e
      }
    }
  }

  // Clear file input by updating the render key
  const handleRemoveAttachment = () => {
    setFiles(null)
    setUploadKey(current => current + 1)
  }

  const placeholder = () => {
    const { showIntroductionReminder, providerCanProvideClinicalCareToPatient, patient } = props
    if (showIntroductionReminder && providerCanProvideClinicalCareToPatient) {
      return `Introduce yourself to ${patientName(patient)}`
    } else {
      return 'Message'
    }
  }

  // Add the `name` property for use in Macros
  const patient = { name: patientName(props.patient), ...props.patient }

  return (
    <>
      <Divider />

      <Box
        display="flex"
        alignItems="center"
        py={1}
        mr={2}
        sx={
          sending
            ? {
                pointerEvents: 'none',
                opacity: 0.5,
              }
            : {}
        }
      >
        <Box sx={{ backgroundColor: 'background.default' }} position="relative" flex={1}>
          <ReactTextareaAutocomplete
            ref={messagesInputRef}
            style={Object.assign({}, INPUT_STYLES, {
              flex: 1,
              overflow: 'hidden',
              fontSize: '1.8rem',
              padding: '0.75em 2.25em 0.75em 0.75em',
              background: 'background.default',
            })}
            value={text}
            disabled={sending}
            onChange={e => {
              setText(e.target.value)
              resizeInput()
            }}
            rows="1"
            placeholder={placeholder()}
            onInput={handleInputEvent}
            renderToBody={true}
            loadingComponent={MacroLoading}
            dropdownStyle={{
              width: 250,
            }}
            trigger={{
              '.': {
                dataProvider: token => macrosFuseSearch(token),
                component: MacroItem,
                output: item =>
                  Mustache.render(
                    item.template,
                    {
                      me,
                      patient,
                    },
                    {},
                    ['{', '}']
                  ),
              },
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              right: 0,
              transform: 'translateY(-50%)',
            }}
          >
            <FileUpload
              maxFiles={1}
              files={null}
              key={uploadKey}
              handleFile={fileWithType => setFiles(fileWithType)}
              handleClear={() => setUploadKey(current => current + 1)}
              supportedImageTypes={IMAGE_TYPES}
              supportedDocTypes={DOCUMENT_TYPES}
              supportMessage="Supports JPG, PNG, TIFF, or PDF"
            />
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          disabled={(text.trim().length === 0 && !(files && files.length > 0)) || sending}
          sx={{
            position: 'relative',
            boxShadow: 'none',
            '&:active': {
              boxShadow: 'none',
            },
            '&:focus': {
              boxShadow: 'none',
            },
          }}
          onClick={handleSendChat}
        >
          {sending ? <Loader inline /> : 'Send'}
        </Button>
      </Box>
    </>
  )
}

export default ChatInput
